<details id="how-to" open>
  <summary>How?</summary>
  Enter a pattern of letters and symbols to find matching English words! Use a <kbd>*</kbd> to represent any number of arbitrary letters (including 0), or use a number to represent exactly that many letters. Try this: <button on:click="demo()">{demoPattern}</button>
</details>
<input type="text" placeholder="type 👏🏿 your 👏🏽 pattern 👏🏻 here"
  aria-label="Enter a pattern to search for matching words." aria-describedby="how-to" aria-controls="results"
  bind:value="pattern" >

<section id="results" aria-live="polite">
  <noscript>You need to enable JavaScript to use Word Please.</noscript>
  {#if pattern.length > 0 && words.length === 0 }
    Loading word list …
  {:elseif pattern.length > 0 && results.length === 0}
    No words match {pattern}.
  {:else}
    {#each results as result}
      <div>{result}</div>
    {/each}
  {/if}
</section>

<script>
  const demoChoices = [
    'w3d*',
    'c8t',
    'tr1l*',
    'ko*a',
    'li4y',
    'm8o*',
    'mov*',
    'new*',
    '*lis',
    'v*ial',
    '*m*m*m*m*',
  ];
  const chooseDemo = function chooseDemoFunc() {
    const demoIndex = Math.floor(Math.random() * demoChoices.length);
    return demoChoices[demoIndex];
  };

  export default {
    data() {
      return {
        words: [],
        pattern: '',
        demoPattern: chooseDemo(),
        results: [],
      };
    },
    oncreate() {
      const wordsWorker = new Worker('load-words.js');
      wordsWorker.addEventListener('message', (ev) => {
        this.set({ words: ev.data });
      });
    },
    methods: {
      demo() {
        const { demoPattern } = this.get();
        this.set({ pattern: demoPattern });

        let newDemo;
        do {
          newDemo = chooseDemo();
        } while (newDemo === demoPattern);
        this.set({ demoPattern: newDemo });
      },
    },
    computed: {
      results({ pattern, words }) {
        const expandedPattern = pattern
          .replace(/\*/g, '.*')
          .replace(/\d+/g, '.{$&}');
        const regex = new RegExp(`^${expandedPattern}$`, 'i');
        const filtered = words.filter(w => regex.test(w));
        return filtered.slice(0, 1000); // Keep it snappy!
      },
    },
  };
</script>
